$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teaserCarousel
    // *
    // *
    var $teaserCarousel = $(".js-teaser-carousel");

    if ($teaserCarousel.length){
        $teaserCarousel.each(function(){
            $(this).slick({
                infinite: true,
                slidesToShow: 3,
                fade: false,
                dots: true,
                arrows: true,
                prevArrow: $(this).parents(".js-paging-nav-wrapper").find(".js-paging-nav-prev"),
                nextArrow: $(this).parents(".js-paging-nav-wrapper").find(".js-paging-nav-next"),
                appendDots: $(this).parents(".js-paging-nav-wrapper").find(".js-paging-nav-dots"),
                speed: 1000,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                            arrows: false
                        }
                    }
                ]
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * logoCarousel
    // *
    // *
    var $logoCarousel = $(".js-logo-carousel");

    if ($logoCarousel.length){
        $logoCarousel.each(function(){
            $(this).slick({
                infinite: true,
                slidesToShow: 4,
                fade: false,
                dots: true,
                arrows: true,
                prevArrow: $(this).parents(".js-paging-nav-wrapper").find(".js-paging-nav-prev"),
                nextArrow: $(this).parents(".js-paging-nav-wrapper").find(".js-paging-nav-next"),
                appendDots: $(this).parents(".js-paging-nav-wrapper").find(".js-paging-nav-dots"),
                speed: 1000,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 650,
                        settings: {
                            slidesToShow: 1,
                            arrows: false
                        }
                    }
                ]
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * countup
	// *
	// *
	function countup() {
		// count up
		var options = {
		  useEasing: true,
		  useGrouping: true,
		  separator: '.',
		  decimal: '.',
		};

		$(".js-countup-number").each(function(){
			var counterID = $(this).attr("id");
				countTo = $(this).data("count");
			var count = new CountUp(counterID, 0, countTo, 0, 4, options);
			count.start();
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * Projekte fadeUp when in viewport by scroll
	// *
	// *
	var offset = 100; /* Abstand vom Browserbottom, wenn Effekt einsetzen soll */

	function fadeInElements(){
		var viewPort = $(window).scrollTop() + $(window).height();

		$(".fade-in-item:visible").each(function(){
			var elementTop = $(this).offset().top;

			if ((elementTop + offset) <= viewPort) {

				// check for countup - if in viepoint fire countUp (once)
				if ($(this).hasClass("js-countup") && !$(this).hasClass("fade-in-show")) {
					countup();
				}


				var delay = $(this).data("delay");
				$(this).css("animation-delay", delay + "s").addClass("fade-in-show");
			}
		});
	}
	$(window).scroll(function() {
		fadeInElements();
	});
	fadeInElements();



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cardCarousel
    // *
    // *
    var $cardCarousel = $(".js-card-carousel");

    if ($cardCarousel.length){
        $cardCarousel.each(function(){
            $(this).slick({
                infinite: true,
                slidesToShow: 3,
                fade: false,
                dots: false,
                arrows: true,
                prevArrow: $(this).parents(".js-paging-nav-wrapper").find(".js-paging-nav-prev"),
                nextArrow: $(this).parents(".js-paging-nav-wrapper").find(".js-paging-nav-next"),
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 8000,
                slidesToShow: 3,
                responsive: [
                    {
                        breakpoint: 1000,
                        settings: {
                            arrows: false
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            arrows: false
                        }
                    }
                ]
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * fancyBox
    // *
    // *
    $('[data-fancybox="gallery"]').fancybox({

    });



     // * * * * * * * * * * * * * * * * * * * * * * * * *
     // * mobile navigation
     // *
     // *
     $('.js-nav-mobile-button').click(function(){
         $('.navMobile').toggleClass('active');
         $('html, body').toggleClass('freeze');
     });

     $('.navMobile .parent span').click(function(){
         $(this).parents('li').toggleClass('is-open is-closed').find('ul').slideToggle();
     });

     // trigger active menü-parents
     $('.navMobile .parent.active span').trigger("click");



     // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video
    // *
    // *
    $(".js-video-button").click(function(){
        $(this).fadeToggle().siblings().get(0).play();
        $(this).siblings().attr("controls", true);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cookie-message
    // *
    // *
    var cookieMessage = Cookies.get('cookie-message');

    $(".js-cookie-message-btn").click(function(){
        $(this).parents(".js-cookie-message").fadeOut();
        Cookies.set('cookie-message', '1', { expires: 365 });
    });

    if (!cookieMessage) {
        $(".js-cookie-message").show();
    }
});
